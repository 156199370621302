/* Make sure a default background color is set for all elements. */
html, body {
  background-color: #fff;
}

.cursor {
  width:26px;
  height:26px;

  /* So that text transitions don't ever leave the boundary of the cursor. */
  overflow:hidden;

  /* Makes the cursor a circle */
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  /* Adds a white bloom effect to the cursor */
  box-shadow: 2px -3px 41px -1px rgba(250,250,250,0.64);

  /* Makes sure this doesn't receive clicks and stuff */
  pointer-events: none;

  /* Fills in cursor and inverts colors */
  mix-blend-mode: difference;
  background: white;

  /* Cursor always draws on top. */
  z-index: 100000;

  /* Smooth transitions for hovering various elements. */
  transition: transform 0.25s, background 0.25s;
}

.cursor-text {
  opacity: 1;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  font-size: 5px;
  color: #111;
  mix-blend-mode: normal;
}