.loading-screen-container {
  position: fixed;
  top: 0; left: 0; z-index: 200000;
  width: 100vw; height: 100vh;
  background: #ffffff;
  transition: opacity 1s;

  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  width: 45vh;
}