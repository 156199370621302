.navbar-container {
  display: flex;
  justify-content: space-around;
  flex: 1;
  /* This offsets the nav-bar from the edges of the page. */
  padding-right: 50px;
  padding-left: 50px;
}

.nav-item {
  font-family: 'Nanum Gothic';
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #111111;
  flex: 1;
  text-align: center;
}

.underline-on-hover {
  display: inline-block;
  position: relative;
}

.underline-on-hover:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #111111;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.underline-on-hover:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.underline-on-hover:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.animated-underline {
  content: '';
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #111111;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}