.project-examples-container {
  width: fill;
  overflow: hidden;

  /* No highlighting. */
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.project-example-header-container {
  width: fill;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 72px;
  padding-left: 72px;
  padding-right: 72px;
  
  background-color: #111;
}

.project-example-header-item {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;

  align-items: center;

  transition: transform 1s ease;

  color: #ffffff;
}

.project-example-container {
  width: fill;
  height: 100vh;

  display: flex;
  justify-content: space-around;
  align-items: center;

  background-color: #111;
}

.project-image-container {
  /* flex: 1; */
}

.project-example-image {
  max-width: 80%;
  height: auto;
  padding: 40px;

  transition: max-width 0.2s ease;
}

.project-example-image:hover {
  max-width: 82%;
}

.align-to-bottom-container {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.align-to-bottom {
  padding-bottom: 100px;
  padding-left: 50px;
  padding-right: 50px;
}