.about-container {
  width: fill;
  height: 100vh;
  overflow: hidden;

  display: flex;
  justify-content: space-around;
  align-items: center;

  background-color: #fff;
}

.about-rolodex {
  transform: translate(-120%);
  flex: 1;
  font-family: 'Cormorant Garamond';
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  margin-left: 10vw;
  margin-right: 10vw;
  text-align: left;

  color: #111111;

  /* Transform-translate should slide in slowly */
  transition: transform 1.25s ease;
}

.about-rolodex-highlight {
  font-weight: 600;
}

.about-image-container {
  text-align: left;
  flex: 1;
}

/* This is jank af and there has to be a better way to position an image 
 * overlay */
.about-image img {
  position: relative;
  width: 80%;
}

.about-image-below {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50.5%);
}

.about-image-overlay {
  z-index: 1;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}