.contact-container {
  width: fill;
  height: 90vh;
  overflow: hidden;
  background-color: #fff;

  /* No highlighting. */
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.center-text-container {
  position: absolute;
  width: 100%;
  height: 90vh;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.center-text {
  overflow: hidden;

  font-family: 'Amiri';
  font-style: italic;
  font-weight: 800;
  font-size: 160px;
  line-height: 200px;
  text-align: center;

  color: #111111;
  text-shadow: 0 0 64px #ffffff, 0 0 64px #ffffff;

  z-index: 100;

  transition: font-size 0.1s;
}

.center-text-float-up {
  transform: translateY(100%);
  transition: transform 1s;
}

.center-text:hover {
  font-size: 170px;
}

.wrapped-text-overlay {
  background-image: linear-gradient( top, 
            rgba( 255, 255, 255, 0 ) 90%, 
            rgba( 255, 255, 255, 1 ) 100% );
  background-image: -moz-linear-gradient( top, 
      rgba( 255, 255, 255, 0 ) 90%, 
      rgba( 255, 255, 255, 1 ) 100% );
  background-image: -ms-linear-gradient( top, 
      rgba( 255, 255, 255, 0 ) 90%, 
      rgba( 255, 255, 255, 1 ) 100% );
  background-image: -o-linear-gradient( top, 
      rgba( 255, 255, 255, 0 ) 90%, 
      rgba( 255, 255, 255, 1 ) 100% );
  background-image: -webkit-linear-gradient( top, 
      rgba( 255, 255, 255, 0 ) 90%, 
      rgba( 255, 255, 255, 1 ) 100% );
  content: "\00a0";
  height: 90vh;
  position: absolute;
  width: fill;
  z-index: 50;
}

.wrapped-text {
  word-wrap: break-word;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  font-size: 40px;
  text-align: center;
  vertical-align: text-top;

  color: #333333;

  padding: 30px;

  transition: opacity 2s;
}

.boldable-text {
  transition: all 0.5s;
}