.mobile-projects-container {
  width: fill;
  overflow: hidden;

  /* Layout with vertical flexbox */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  padding-top: 120px; /* display under banner */

  text-align: left;

  /* No highlighting. */
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  
}

.mobile-project-item {
  max-width: 100%;
  
  font-family: 'Amiri';
  font-style: italic;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
}

.mobile-project-item img {
  max-width:100%;
}