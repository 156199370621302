.modal-container {
  transition: opacity 0.5s;

  height: 100vh;
  width: fill;
	position: fixed;
  top: 0;
	left: 0;

  z-index: 99999;
  background-color: #fff;
  overflow-y: scroll;
}

.modal-container img {
  max-width: 100%;
}

.modal-close {
  width: 30px;
  position: fixed;
  top: 10px;
  right: 30px;
  padding: 20px;
  transition: transform 0.5s ease;
}

.modal-close-mobile {
  width: 20px;
  position: fixed;
  top: 10px;
  right: 15px;
  padding: 20px;
  transition: transform 0.5s ease;
}

.modal-close:hover {
  transform: rotate(180deg) scale(1.1);
}

.modal-close-mobile:hover {
  transform: rotate(180deg) scale(1.1);
}

.modal-text {
  padding-left: 20vw;
  padding-right: 20vw;
  text-align: left;
}

.modal-text-mobile {
  padding-left: 10vw;
  padding-right: 10vw;
  text-align: left;
}

.modal-text h1 {
  font-family: 'Amiri';
  font-style: italic;
  font-weight: 400;
  font-size: 150px;
  line-height: 130px;
  padding-top: 120px;
  padding-bottom: 100px;
  margin: 0;
  color: #111;
}

.modal-text-mobile h1 {
  font-family: 'Amiri';
  font-style: italic;
  font-weight: 400;
  font-size: 70px;
  line-height: 70px;
  padding-top: 40px;
  padding-bottom: 10px;
  margin: 0;
  color: #111;
}

.modal-text h2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  color: #111;
}

.modal-text-mobile h2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  color: #111;
}

.modal-text h3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  color: #111;
}

.modal-text-mobile h3 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  color: #111;
}

.modal-text h4 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  color: #111;
}

.modal-text-mobile h4 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  color: #111;
}

.modal-text p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  color: #111;
}

.modal-text-mobile p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #111;
}

.modal-text li {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  color: #111;
}

.modal-text-mobile li {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #111;
}

.modal-text figcaption {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #111;
  /* padding-top: 10px; */
}

.modal-text-mobile figcaption {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #111;
  /* padding-top: 10px; */
}

.modal-container a {
  all: unset;
  text-decoration: underline dotted 1px; 
  text-underline-offset: 2px;
  transition: color 0.15s;
}

.modal-container a:hover {
  color: rgb(25, 164, 228);
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */ 
}

.soundcloud-wrapper {
  position: relative;
}

.spotify-wrapper {
  position: relative;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

iframe .empty {

}

/* .modal-text h2 {
  font-family: 'Amiri';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  color: #111;
}

.modal-text p {
  font-family: 'Cormorant Garamond';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #111;
} */

#modal-subtitle-container {
  overflow: hidden;
}

.modal-text #modal-subtitle {
  transform: translateY(120%);
  transition: transform 1s;
}

.modal-text-mobile #modal-subtitle {
  /* Default to visible on mobile */
  transition: transform 1s;
}