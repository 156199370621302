.menu-container {
	display: flex;
  justify-content: space-between;
  align-items: baseline;
  
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.menu-child {
    flex: 1;
}

.arrow-container {
	padding-left: 6px;
}

.arrow {
	flex: none;
  height: 8px;
  width: 8px;

  transition: transform 0.5s;
}

.dropdown-container {
  overflow: hidden;
  position: absolute;
  padding-right: 32px;
  padding-bottom: 32px;
  padding-left: 16px;
  padding-top: 4px;
}

.dropdown {
  max-width: 190px;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 30px;

  transform: translateY(-105%);
  transition: transform 0.5s;

  /* VARIANT 2 */
  background-color: #fff;
  border-top: 1px solid #111;
  border-bottom: 1px solid #111;

  /* VARIANT 1 */
  /* border-top: 1px solid #111;
  border-bottom: 1px solid #111;
  box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.2); */

  /* VARIANT 3 */
  /* border-top: 1px solid #111;
  box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.2); */
}

.menu-item {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 18px;
  color: #111111;
  text-align: left;

  padding-top: 10px;
  padding-bottom: 10px;
}

.menu-item:hover > .underline-on-hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}